/* global Office */

import { persistManagerInstance } from "./PersistManager";

export function setOfficeSettings(key: string, value: any) {
  persistManagerInstance.update({
    objectName: key,
    properties: value,
  });
}

export function getOfficeSettings(key: string) {
  return Office.context.document?.settings?.get(key);
}
