export const _tablesMap = {
  "structure-table-numbers": {
    id: "structure-table-numbers",
    title: "Simple table with numbers",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/structure/images/structure-table-numbers.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
          },
        },
      },
    },
    settings: {
      showAsTable: true,
    },
  },
  "structure-table-chart": {
    id: "structure-table-chart",
    title: "Simple table with a chart",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/structure/images/structure-table-chart.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
          },
        },
      },
    },
    settings: {
      showAsTable: false,
    },
  },
  "comparison-table-one": {
    id: "comparison-table-one",
    title: "One comparison",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/comparison/images/comparison-table-one.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
            "2": {
              text: "160",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "110",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
            "2": {
              text: "105",
            },
          },
        },
      },
    },
    settings: {
      showAsTable: false,
    },
  },
  "comparison-table-two": {
    id: "comparison-table-two",
    title: "Two comparison",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/comparison/images/comparison-table-two.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "PY",
            },
            "3": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "155",
            },
            "3": {
              text: "150",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "170",
            },
            "3": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
            "3": {
              text: "160",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "150",
            },
            "3": {
              text: "170",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "145",
            },
            "3": {
              text: "155",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
            "2": {
              text: "130",
            },
            "3": {
              text: "160",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "115",
            },
            "3": {
              text: "110",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
            "2": {
              text: "105",
            },
            "3": {
              text: "105",
            },
          },
        },
      },
    },
    settings: {
      showAsTable: false,
    },
  },
  "comparison-table-three": {
    id: "comparison-table-three",
    title: "Three comparison",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/comparison/images/comparison-table-three.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "PY",
            },
            "3": {
              text: "BU",
            },
            "4": {
              text: "FC",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "155",
            },
            "3": {
              text: "150",
            },
            "4": {
              text: "160",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "170",
            },
            "3": {
              text: "160",
            },
            "4": {
              text: "145",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
            "3": {
              text: "160",
            },
            "4": {
              text: "145",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "150",
            },
            "3": {
              text: "170",
            },
            "4": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "145",
            },
            "3": {
              text: "155",
            },
            "4": {
              text: "145",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
            "2": {
              text: "130",
            },
            "3": {
              text: "160",
            },
            "4": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "115",
            },
            "3": {
              text: "110",
            },
            "4": {
              text: "130",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
            "2": {
              text: "105",
            },
            "3": {
              text: "105",
            },
            "4": {
              text: "120",
            },
          },
        },
      },
    },
    settings: {
      showAsTable: false,
    },
  },
  "cross-table-simple": {
    id: "cross-table-simple",
    title: "Simple cross table",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/cross/images/cross-table-simple.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Month",
            },
            "1": {
              text: "Audio",
            },
            "2": {
              text: "Baby Care",
            },
            "3": {
              text: "Comp",
            },
            "4": {
              text: "Hair Care",
            },
            "5": {
              text: "Mobile",
            },
            "6": {
              text: "Oral Care",
            },
            "7": {
              text: "Skin Care",
            },
            "8": {
              text: "Video",
            },
            "9": {
              text: "Wearable",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "83329",
            },
            "2": {
              text: "338313",
            },
            "3": {
              text: "106422",
            },
            "4": {
              text: "76442",
            },
            "5": {
              text: "233961",
            },
            "6": {
              text: "4372",
            },
            "7": {
              text: "8635",
            },
            "8": {
              text: "41307",
            },
            "9": {
              text: "347659",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "113952",
            },
            "2": {
              text: "312216",
            },
            "3": {
              text: "26272",
            },
            "4": {
              text: "127250",
            },
            "5": {
              text: "199055",
            },
            "6": {
              text: "2755",
            },
            "7": {
              text: "7622",
            },
            "8": {
              text: "66154",
            },
            "9": {
              text: "311798",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "215457",
            },
            "2": {
              text: "295621",
            },
            "3": {
              text: "181653",
            },
            "4": {
              text: "187766",
            },
            "5": {
              text: "239958",
            },
            "6": {
              text: "20280",
            },
            "7": {
              text: "4935",
            },
            "8": {
              text: "49844",
            },
            "9": {
              text: "309386",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "178617",
            },
            "2": {
              text: "481192",
            },
            "3": {
              text: "65716",
            },
            "4": {
              text: "120910",
            },
            "5": {
              text: "299027",
            },
            "6": {
              text: "1482",
            },
            "7": {
              text: "9518",
            },
            "8": {
              text: "67001",
            },
            "9": {
              text: "363589",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "158154",
            },
            "2": {
              text: "288304",
            },
            "3": {
              text: "46957",
            },
            "4": {
              text: "104938",
            },
            "5": {
              text: "207221",
            },
            "6": {
              text: "13730",
            },
            "7": {
              text: "1728",
            },
            "8": {
              text: "58116",
            },
            "9": {
              text: "310995",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "127178",
            },
            "2": {
              text: "273629",
            },
            "3": {
              text: "55603",
            },
            "4": {
              text: "115213",
            },
            "5": {
              text: "264442",
            },
            "6": {
              text: "10947",
            },
            "7": {
              text: "9055",
            },
            "8": {
              text: "59447",
            },
            "9": {
              text: "371236",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "133010",
            },
            "2": {
              text: "236845",
            },
            "3": {
              text: "10132",
            },
            "4": {
              text: "103142",
            },
            "5": {
              text: "206553",
            },
            "6": {
              text: "2451",
            },
            "7": {
              text: "2339",
            },
            "8": {
              text: "66707",
            },
            "9": {
              text: "328444",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "82613",
            },
            "2": {
              text: "329146",
            },
            "3": {
              text: "10555",
            },
            "4": {
              text: "95924",
            },
            "5": {
              text: "218048",
            },
            "6": {
              text: "4245",
            },
            "7": {
              text: "26771",
            },
            "8": {
              text: "79005",
            },
            "9": {
              text: "410819",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "68512",
            },
            "2": {
              text: "363022",
            },
            "3": {
              text: "22802",
            },
            "4": {
              text: "115577",
            },
            "5": {
              text: "220051",
            },
            "6": {
              text: "6979",
            },
            "7": {
              text: "3764",
            },
            "8": {
              text: "58415",
            },
            "9": {
              text: "339497",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "168488",
            },
            "2": {
              text: "524682",
            },
            "3": {
              text: "155838",
            },
            "4": {
              text: "163962",
            },
            "5": {
              text: "375476",
            },
            "6": {
              text: "28958",
            },
            "7": {
              text: "8702",
            },
            "8": {
              text: "64372",
            },
            "9": {
              text: "359602",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "96446",
            },
            "2": {
              text: "579961",
            },
            "3": {
              text: "9353",
            },
            "4": {
              text: "182363",
            },
            "5": {
              text: "366211",
            },
            "6": {
              text: "46304",
            },
            "7": {
              text: "4284",
            },
            "8": {
              text: "84912",
            },
            "9": {
              text: "408238",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "174985",
            },
            "2": {
              text: "1098585",
            },
            "3": {
              text: "35884",
            },
            "4": {
              text: "165325",
            },
            "5": {
              text: "247100",
            },
            "6": {
              text: "15803",
            },
            "7": {
              text: "17758",
            },
            "8": {
              text: "70191",
            },
            "9": {
              text: "439920",
            },
          },
        },
      },
    },
    isMultiples: true,
    settings: {
      showAsTable: false,
      groupsInColumns: true,
    },
  },
  "p-and-l": {
    id: "p-and-l",
    title: "P&L",
    image:
      "https://localhost:3000/packages/tables-chooser/react/tables/categories/dimensions/calculation/images/p-and-l.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "AC",
            },
            "2": {
              text: "PL",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Net Sales",
            },
            "1": {
              text: "5400",
            },
            "2": {
              text: "4900",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Cost of goods sold",
            },
            "1": {
              text: "3500",
            },
            "2": {
              text: "3100",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Gross profit",
            },
            "1": {
              text: "1900",
            },
            "2": {
              text: "1800",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Other operating income",
            },
            "1": {
              text: "1636",
            },
            "2": {
              text: "1758",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "SG&A",
            },
            "1": {
              text: "1887",
            },
            "2": {
              text: "1402",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Operating profit",
            },
            "1": {
              text: "1649",
            },
            "2": {
              text: "2156",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Taxes",
            },
            "1": {
              text: "1348",
            },
            "2": {
              text: "1200",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Net profit",
            },
            "1": {
              text: "301",
            },
            "2": {
              text: "956",
            },
          },
        },
      },
    },
    settings: {
      showAsTable: false,
    },
  },
};
