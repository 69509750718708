import type { DataView } from "./tableData.types";

export const simpleTableData: DataView = {
  values: [
    { source: { displayName: "ACT" }, values: [252, 283] },
    { source: { displayName: "BUD" }, values: [304, 281] },
  ],
  categories: [{ values: ["Jan", "Feb"], source: { displayName: "Category" } }],
  rowFields: [],
  columnFields: [],
  valueFields: ["ACT", "BUD"],
  rowGrandTotal: false,
  columnGrandTotal: false,
  rowNames: [],
  columnNames: [],
  valueNames: ["ACT", "BUD"],
  metadata: {
    columns: [
      { displayName: "ACT", roles: { Values: true }, type: { numeric: true }, queryName: "ACT", index: 0 },
      { displayName: "BUD", roles: { Values: true }, type: { numeric: true }, queryName: "BUD", index: 1 },
    ],
  },
};

export const emptyTableData: DataView = {
  values: [],
  categories: [],
  rowFields: [],
  columnFields: [],
  valueFields: [],
  rowGrandTotal: false,
  columnGrandTotal: false,
  metadata: { columns: [] },
  rowNames: [],
  columnNames: [],
  valueNames: [],
};
