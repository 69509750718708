import { MutationSummary } from "mutation-summary";
import type { Summary } from "mutation-summary";

type EmptyData = {};

export class DOMStorage<T> {
  private elementId;
  private elementDataAttibute;

  constructor(elementId: string, elementDataAttibute: string, data?: T) {
    this.elementId = elementId;
    this.elementDataAttibute = elementDataAttibute;

    this.init(data);
  }

  private init(data: T) {
    if (!document.getElementById(this.elementId)) {
      this.addElement();
    }
    if (data !== undefined) {
      this.set(data);
    }
  }

  private addElement() {
    const DOMStorageElement = document.createElement("div");
    DOMStorageElement.setAttribute("id", this.elementId);
    DOMStorageElement.setAttribute(this.elementDataAttibute, "");
    document.body.prepend(DOMStorageElement);
  }

  public set(data: T) {
    const DOMStorageElement = document.getElementById(this.elementId);
    DOMStorageElement.setAttribute(this.elementDataAttibute, JSON.stringify(data));
  }

  public subscribe(handler: (data: T | EmptyData) => void): () => void {
    const rootNode = document.getElementById(this.elementId);
    const observer = new MutationSummary({
      callback: (changeSummaries: Summary[]) => this.handleDataChange(changeSummaries, handler),
      rootNode,
      queries: [{ attribute: this.elementDataAttibute }],
    });

    return observer.disconnect;
  }

  private handleDataChange(changeSummaries: Summary[], handler: (data: T | EmptyData) => void) {
    const storeElement: Element = changeSummaries[0].valueChanged[0] as Element;
    const dataString: string = storeElement.getAttribute(this.elementDataAttibute);
    handler(JSON.parse(dataString));
  }
}
