import BaseObserver from "./BaseObserver";
import type {PersistObject} from "../PersistManager";

export default abstract class PersistObserver implements BaseObserver<PersistObject> {
    public static CLASS_NAME = "PersistObserver";

    abstract update(data: PersistObject):void;

    getClassName(): string {
        return PersistObserver.CLASS_NAME;
    }
}
