import type { DataView as TableDataView } from "@zebrabi/table-data";
import type { DataView as MatrixDataView } from "@zebrabi/matrix-data";
import { getOfficeStorageTableData, getOfficeStorageMatrixData } from "@zebrabi/table-data";
import { Observable } from "@zebrabi/observable";

let xSpreadSheetStore: Observable<any>;

export function initXSpreadSheetStoreWithTableData() {
  xSpreadSheetStore = new Observable<TableDataView>(getOfficeStorageTableData());
}

export function initXSpreadSheetStoreWithMatrixData() {
  xSpreadSheetStore = new Observable<MatrixDataView>(getOfficeStorageMatrixData());
}

export function setXSpreadSheetData<T>(data: T) {
  return xSpreadSheetStore.set(data);
}

export function subscribeToXSpreadSheetData<T>(handler: (data: T) => void) {
  return xSpreadSheetStore.subscribe(handler);
}
