import "core-js/actual/structured-clone";
import { DataView as TableDataView, simpleTableData } from "@zebrabi/table-data";
import {
  DataView as MatrixDataView,
  DataViewMatrixNode,
  DataViewMetadataColumn,
  simpleMatrixData,
} from "@zebrabi/matrix-data";
import type { SheetData, RowData } from "x-data-spreadsheet";
import {
  stringWithCommaToFloat,
  validateTableData,
  validateTableDataForSmallMultiples,
} from "@zebrabi/table-data/helpers/tableDataHelper";
import { getOfficeStorageMatrixData, getOfficeStorageTableData, getOfficeStorageXSpreadsheetData } from "@zebrabi/table-data/helpers/officeStorageHelper";
import { _chartMap } from "./tempChartMap";
import { ChartChooserStore } from "../../../chart-chooser/store";
import { TablesChooserStore } from "@zebrabi/tables-chooser";
import { _tablesMap } from "./tempTablesMap";
import { MeasureRoles, tryGetMeasureRoleFromName } from "@zebrabi/data-helpers/fieldAssignment";
import { getOfficeSettings } from "@zebrabi/office-settings";
import { tryParseHierarchicalDataView } from "@zebrabi/data-helpers/hirarchyPPTparsingHelpers";
import { flagHandler } from "@zebrabi/zebrabi-core";
// TableData

function xSpreadsheetRowsToTableDataCategories(rows: SheetData["rows"]): string[] {
  delete rows["len"];
  return Object.values(rows).reduce((categoryArray: any[], row: RowData, index: number) => {
    if (index === 0) return categoryArray;

    const cells = Object.values(row?.cells || []);
    const nextCategory = cells[0]?.text;

    return [...categoryArray, nextCategory ? nextCategory : null];
  }, []);
}

function xSpreadsheetRowsToTableDataValues(rows: SheetData["rows"]): (string | number | null)[][] {
  delete rows["len"];
  return Object.values(rows)
    .filter((row) => row.cells[0].text)
    .reduce((valueColumnsArray: (number | null)[][] | [], row: RowData, index: number) => {
      if (index === 0) return valueColumnsArray;
      const cells = Object.values(row?.cells || []);
      const values: (string | number | null)[] = cells
        .filter((_cell, index) => index !== 0) // Skip category cell
        .map((cell) =>
          cell.text && cell.text !== ""
            ? stringWithCommaToFloat(cell.text[0] === "=" ? cell.calculatedValue : cell.text)
            : null
        )
        .map((cell) => (typeof cell === "string" ? cell.trim() : cell));

      while (values.length < valueColumnsArray.length) {
        values.push(null);
      }

      if (values.length === 0) return valueColumnsArray;
      return values.map((value, index) => [...(valueColumnsArray[index] || []), value]);
    }, [])
    .filter((valuesArray) => valuesArray.some((value) => !!value));
}

function looksLikeCommentHeader(header: string): boolean {
  if (!header || !header.toLowerCase) {
    return false;
  }
  const headerLC = header.toLowerCase();
  return headerLC.startsWith("comm") || headerLC.startsWith("kom");
}

export function xSpreadsheetToTableData(data: SheetData): TableDataView {
  const categoriesName = Object.values(data.rows[0]?.cells || [])[0]?.text; // The top left cell
  const columnHeadings = Object.values(data.rows[0]?.cells || []) // First row without the first cell
    .filter((_cell, index) => index !== 0)
    .filter((cell) => !!cell?.text)
    .map((cell) => cell?.text);
  const categoryColumn = xSpreadsheetRowsToTableDataCategories(data.rows); // First column without the first row
  const valueColumns = xSpreadsheetRowsToTableDataValues(data.rows); // Other columns without the first row
  return validateTableData({
    values: columnHeadings.map((heading, index) => ({
      source: { displayName: heading },
      values: valueColumns[index]?.map((vCol: any) =>
        looksLikeCommentHeader(heading) ? vCol : isNaN(vCol) || vCol === "" ? null : vCol
      ),
    })),
    categories: [{ values: categoryColumn, source: { displayName: categoriesName } }],
    rowFields: [],
    columnFields: [],
    valueFields: columnHeadings,
    rowGrandTotal: false,
    columnGrandTotal: false,
    rowNames: [],
    columnNames: [],
    valueNames: columnHeadings,
    metadata: {
      columns: columnHeadings.map((heading, index) => ({
        displayName: heading,
        roles: { [tryGetMeasureRoleFromName(heading) || MeasureRoles.Values]: true },
        type: looksLikeCommentHeader(heading) ? { text: true } : { numeric: true },
        queryName: heading,
        index,
      })),
    },
  });
}

export function xSpreadsheetToTableDataforSmallMultiples(data: SheetData): TableDataView {
  const categoriesName = Object.values(data.rows[0]?.cells || [])[0]?.text; // The top left cell
  const columnHeadings = Object.values(data.rows[0]?.cells || []) // First row without the first cell
    .filter((_cell, index) => index !== 0)
    .filter((cell) => !!cell?.text)
    .map((cell) => cell?.text);
  const categoryColumn = xSpreadsheetRowsToTableDataCategories(data.rows); // First column without the first row
  const valueColumns = xSpreadsheetRowsToTableDataValues(data.rows); // Other columns without the first row
  return validateTableDataForSmallMultiples({
    values: [
      ...columnHeadings.map((heading, index) => ({
        source: { displayName: categoriesName, groupName: heading },
        values: valueColumns[index],
      })),
    ],
    categories: [{ values: categoryColumn, source: { displayName: categoriesName } }],
    rowFields: [],
    columnFields: [],
    valueFields: columnHeadings,
    rowGrandTotal: false,
    columnGrandTotal: false,
    rowNames: [categoriesName],
    columnNames: ["Group"],
    valueNames: [categoriesName],
    metadata: {
      columns: [
        {
          displayName: categoriesName,
          roles: { Values: true },
          type: { numeric: true },
          queryName: categoriesName,
          index: 0,
        },
      ],
    },
  });
}

export function parseChartData(data: SheetData): TableDataView {
  return _chartMap[ChartChooserStore?.data?.value?.selectedChart]?.isMultiples
    ? xSpreadsheetToTableDataforSmallMultiples(data)
    : xSpreadsheetToTableData(data);
}

function getXSpreadSheetDataRow(index, rowName, valuesArray): Record<number, RowData> {
  return {
    [index]: {
      cells: {
        0: {
          text: rowName,
        },
        ...valuesArray.reduce(
          (valuesObject, value, index) => ({ ...valuesObject, [index + 1]: { text: value?.toString() } }),
          {}
        ),
      },
    },
  };
}

export function tableDataToXSpreadsheet(tableData: TableDataView): SheetData {
  const rowNames = tableData.categories[0].values;
  const valuesArrays = rowNames.map((_rowName, index) =>
    tableData.values.map((value) => (value.values ? value.values[index] : ""))
  );
  return {
    rows: {
      ...getXSpreadSheetDataRow(0, tableData.categories[0].source.displayName, tableData.valueNames),
      ...rowNames.reduce(
        (valuesObject, rowName, index) => ({
          ...valuesObject,
          ...getXSpreadSheetDataRow(index + 1, rowName, valuesArrays[index]),
        }),
        {}
      ),
    },
  };
}

export function tableDataToXSpreadsheetForSmallMultiples(tableData: TableDataView): SheetData {
  const rowNames = tableData.categories[0].values;
  const valuesArrays = rowNames.map((_rowName, index) =>
    tableData.values.map((value) => (value.values ? value.values[index] : ""))
  );
  return {
    rows: {
      ...getXSpreadSheetDataRow(0, tableData.categories[0].source.displayName, tableData.valueFields),
      ...rowNames.reduce(
        (valuesObject, rowName, index) => ({
          ...valuesObject,
          ...getXSpreadSheetDataRow(index + 1, rowName, valuesArrays[index]),
        }),
        {}
      ),
    },
  };
}

export enum VisualType {
  CHARTS = "CHARTS",
  TABLES = "TABLES",
}

export const MIN_COLUMN_COUNT_FOR_SMALL_MULTIPLES = 5;
export const chartsSupportSmallMultiples = [
  "part-whole-structure-bar", //stacked bar chart
  "part-whole-time-column",   //stacked column chart
  "part-whole-time-area",     //stacked area chart
  "time-small-multiples-1",   //"small multiples column chart"
];

export function getInitialXSpreadSheetData({ visualType }: { visualType: VisualType }): SheetData {
  if (visualType === VisualType.CHARTS) {
    //TODO to commit: this was a quick fix for when the number of values is lower than 5. For more details see https://zebrabi.atlassian.net/browse/ZVE-929
    const selectedChart: string = getOfficeSettings("ChartChooserStore")?.selectedChart.trim();
    const isSmallMultipleOrStacked = chartsSupportSmallMultiples.includes(selectedChart);
    const dataView = getOfficeStorageTableData();
    if (dataView?.values?.length >= MIN_COLUMN_COUNT_FOR_SMALL_MULTIPLES || isSmallMultipleOrStacked) {
      return tableDataToXSpreadsheetForSmallMultiples(dataView || simpleTableData);
    }
    return tableDataToXSpreadsheet(dataView || simpleTableData);
  }

  if (visualType === VisualType.TABLES) {
    try {
      const dataView = getOfficeStorageMatrixData();
      if (dataView?.metadata.columns.filter((col) => col.roles.Category).length > 1) {  // use for all cases, not only for hierarchical data?
        return getOfficeStorageXSpreadsheetData();
      }
      const isDataForSmallMultiples = !!dataView?.metadata.columns.find((col) => col.roles.Group);
      return isDataForSmallMultiples
        ? matrixDataToXSpreadsheetForSmallMultiples(dataView || simpleMatrixData)
        : matrixDataToXSpreadsheet(dataView || simpleMatrixData);
    }
    catch (error) {
      return getOfficeStorageXSpreadsheetData() ?? matrixDataToXSpreadsheet(simpleMatrixData);
    }
  }

  throw new Error("Visual type not supported");
}

export function concatCommentsToSheetData(data: SheetData, comments: SheetData): SheetData {
  const columnsCount = Object.keys(data.rows[0].cells).length;
  return {
    rows: {
      ...Object.fromEntries(
        Object.entries(data.rows).map(([key, value]) => [
          key,
          { cells: { ...value.cells, [columnsCount]: comments.rows[key]?.cells[0] || { text: "" } } },
        ])
      ),
    },
  };
}

// MatrixData

export function matrixDataToXSpreadsheet(matrixData: MatrixDataView): SheetData {
  return {
    rows: {
      ...getXSpreadSheetDataRow(0, matrixData.matrix.rows.levels[0]?.sources[0]?.displayName, matrixData.valueFields),
      ...matrixData.matrix.rows.root.children.reduce(
        (sheetRows, matrixRow, index) => ({
          ...sheetRows,
          [index + 1]: {
            cells: {
              0: { text: matrixRow.value },
              ...Object.fromEntries(
                Object.entries(matrixRow.values).map(([key, valueObject]) => [
                  Number(key) + 1,
                  { text: valueObject.value },
                ])
              ),
            },
          },
        }),
        {}
      ),
    },
  };
}

export function matrixDataToXSpreadsheetForSmallMultiples(matrixData: MatrixDataView): SheetData {
  return {
    rows: {
      ...getXSpreadSheetDataRow(
        0,
        matrixData.metadata.columns.find((col) => col.roles.Category)?.displayName,
        matrixData.matrix.columns.root.children.map((child) => child.value)
      ),
      ...matrixData.matrix.rows.root.children.reduce(
        (sheetRows, matrixRow, index) => ({
          ...sheetRows,
          [index + 1]: {
            cells: {
              0: { text: matrixRow.value },
              ...Object.fromEntries(
                Object.entries(matrixRow.values).map(([key, valueObject]) => [
                  Number(key) + 1,
                  { text: valueObject.value },
                ])
              ),
            },
          },
        }),
        {}
      ),
    },
  };
}

function columnHeadingsToMatrixValueSources(columnHeadings: string[]): DataViewMetadataColumn[] {
  return columnHeadings.map((columnHeading, index) => ({
    displayName: columnHeading,
    roles: { [tryGetMeasureRoleFromName(columnHeading) || MeasureRoles.Values]: true },
    type: looksLikeCommentHeader(columnHeading) ? { text: true } : { numeric: true },
    queryName: columnHeading,
    index,
  }));
}

function categoryAndValueColumnsToMatrixChildren(
  categoryColumn: string[],
  valueColumns: (string | number | null)[][],
  indexOfComments: number = -1,
  columnHeadings: string[]
): DataViewMatrixNode[] {
  return categoryColumn.map((category, index) => ({
    level: 0,
    value: category,
    values: valueColumns.reduce((values, _valueColumn, columnIndex) => {
      if (!columnHeadings[columnIndex]) return values;
      return {
        ...values,
        [columnIndex]: {
          value:
            columnIndex === indexOfComments
              ? valueColumns[columnIndex][index]
              : isNaN(valueColumns[columnIndex][index] as number) || valueColumns[columnIndex][index] === ""
                ? null
                : valueColumns[columnIndex][index],
        },
      };
    }, {}),
    isSubtotal: false,
  }));
}

export function parseTablesData(data: SheetData): MatrixDataView {
  return _tablesMap[TablesChooserStore?.data?.value?.selectedTable]?.isMultiples
    ? xSpreadsheetToMatrixDataForSmallMultiples(data)
    : xSpreadsheetToMatrixData(data);
}

export function xSpreadsheetToMatrixData(data: SheetData): MatrixDataView {
  const categoryColumns = getNumberOfCategoryColumns(data);
  if (categoryColumns > 1) {  // use for all cases, not only for hierarchical data?
    const dv = tryParseHierarchicalDataView(data, categoryColumns);
    if (dv) {
      return dv;
    }
  }

  const categoriesName = Object.values(data.rows[0]?.cells || [])[0]?.text; // The top left cell
  // const categoriesNames = Object.values(data.rows[0]?.cells || []) // 
  //   .filter((_cell, index) => index < categoryColumns)
  //   .filter((cell) => !!cell?.text)
  //   .map((cell) => cell?.text);

  const columnHeadings = Object.values(data.rows[0]?.cells || []) // First row without the first cell
    .filter((_cell, index) => index !== 0)
    .filter((cell) => !!cell?.text)
    .map((cell) => cell?.text);

  const categoryColumn = xSpreadsheetRowsToTableDataCategories(data.rows); // First column without the first row
  const valueColumns = xSpreadsheetRowsToTableDataValues(data.rows); // Other columns without the first row

  return {
    matrix: {
      columns: {
        levels: [
          {
            sources: columnHeadingsToMatrixValueSources(columnHeadings),
          },
        ],
        root: {
          children: [{ level: 0 }],
        },
      },
      rows: {
        levels: [
          {
            sources: [
              {
                displayName: categoriesName,
                roles: { Category: true },
                type: { text: true },
                queryName: categoriesName,
              },
            ],
          },
        ],
        root: {
          children: categoryAndValueColumnsToMatrixChildren(
            categoryColumn,
            valueColumns,
            columnHeadings.findIndex((heading) => looksLikeCommentHeader(heading)),
            columnHeadings
          ),
        },
      },
      valueSources: columnHeadingsToMatrixValueSources(columnHeadings),
    },
    rowFields: [],
    columnFields: [],
    valueFields: columnHeadings,
    rowGrandTotal: false,
    columnGrandTotal: false,
    rowNames: [],
    columnNames: [],
    valueNames: columnHeadings,
    metadata: {
      columns: columnHeadings.map((heading, index) => {
        return {
          displayName: heading,
          roles: { [tryGetMeasureRoleFromName(heading) || MeasureRoles.Values]: true },
          type: looksLikeCommentHeader(heading) ? { text: true } : { numeric: true },
          queryName: heading,
          index,
        };
      }),
    },
  };
}

export function xSpreadsheetToMatrixDataForSmallMultiples(data: SheetData): MatrixDataView {
  //const categoriesName = Object.values(data.rows[0]?.cells || [])[0]?.text; // The top left cell
  const columnHeadings = Object.values(data.rows[0]?.cells || []) // First row without the first cell
    .filter((_cell, index) => index !== 0)
    .filter((cell) => !!cell?.text)
    .map((cell) => cell?.text);
  const categoryColumn = xSpreadsheetRowsToTableDataCategories(data.rows); // First column without the first row
  const valueColumns = xSpreadsheetRowsToTableDataValues(data.rows); // Other columns without the first row
  return {
    matrix: {
      columns: {
        levels: [
          {
            sources: [
              {
                displayName: "Group",
                roles: {
                  Group: true,
                },
                type: {
                  text: true,
                },
                queryName: "Group",
              },
            ],
          },
        ],
        root: {
          children: columnHeadings.map((gn) => ({
            level: 0,
            value: gn,
          })),
        },
      },
      rows: {
        levels: [
          {
            sources: [
              {
                displayName: "Category",
                roles: {
                  Category: true,
                },
                type: {
                  text: true,
                },
                queryName: "Category",
              },
            ],
          },
        ],
        root: {
          children: categoryAndValueColumnsToMatrixChildren(
            categoryColumn,
            valueColumns,
            columnHeadings.findIndex((heading) => looksLikeCommentHeader(heading)),
            columnHeadings
          ),
        },
      },
      valueSources: [
        {
          displayName: "Sum of Revenue",
          roles: {
            Values: true,
          },
          type: {
            numeric: true,
          },
          queryName: "Sum of Revenue",
          index: 0,
        },
      ],
    },
    rowFields: [],
    columnFields: [],
    valueFields: ["Sum of Revenue"],
    rowGrandTotal: false,
    columnGrandTotal: false,
    rowNames: ["Category"],
    columnNames: ["Group"],
    valueNames: ["Sum of Revenue"],
    metadata: {
      columns: [
        {
          displayName: "Category",
          roles: {
            Category: true,
          },
          type: {
            text: true,
          },
          queryName: "Category",
        },
        {
          displayName: "Group",
          roles: {
            Group: true,
          },
          type: {
            text: true,
          },
          queryName: "Group",
        },
        {
          displayName: "Sum of Revenue",
          roles: {
            Values: true,
          },
          type: {
            numeric: true,
          },
          queryName: "Sum of Revenue",
          index: 0,
        },
      ],
    },
  };
}

export function getNumberOfCategoryColumns(data: SheetData): number {
  if (!data?.rows || !data.rows[0]?.cells) {
    return 1;
  }

  delete data.rows["len"];  // this properts sometimes appears on the rows and is deleted so that it does not interfere with the calculations
  const sourceDataRows = Object.keys(data.rows).map((key) => {
    return data.rows[+key];
  });

  const firstRowsCells = sourceDataRows[0].cells;
  const firstRowsCellsKeys = Object.keys(firstRowsCells);
  const totalColumnsCount = firstRowsCellsKeys.length;
  let numberOfTextColumns = 0;
  for (let i = 0; i < totalColumnsCount; i++) {
    const isStringCol = sourceDataRows.every((row) => {
      const cell = row.cells[i];
      return isNaN(Number(cell?.text)) || cell?.text === ""; //typeof cell?.text === "string";
    });

    if (isStringCol) {
      numberOfTextColumns++;
    }
    else {
      break;
    }
  }

  return numberOfTextColumns;
}

