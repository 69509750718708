// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#table-data-editor-x-spreadsheet {
  z-index: 1000;
  position: absolute;
  right: 0;
  top: 20px;
  width: 50%;
  height: calc(100% - 20px);
}

#table-data-editor-x-spreadsheet::-webkit-scrollbar {
  display: none;
}

.x-spreadsheet {
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
}

@media (orientation: portrait) {
  #table-data-editor-x-spreadsheet {
    top: 50%;
    bottom: 0 !important;
    width: 100% !important;
    height: 50% !important;
    overflow-y: scroll;
  }
}`, "",{"version":3,"sources":["webpack://./packages/table-data-editor/x-spreadsheet/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oFAAoF;AACtF;;AAEA;EACE;IACE,QAAQ;IACR,oBAAoB;IACpB,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":["#table-data-editor-x-spreadsheet {\n  z-index: 1000;\n  position: absolute;\n  right: 0;\n  top: 20px;\n  width: 50%;\n  height: calc(100% - 20px);\n}\n\n#table-data-editor-x-spreadsheet::-webkit-scrollbar {\n  display: none;\n}\n\n.x-spreadsheet {\n  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);\n}\n\n@media (orientation: portrait) {\n  #table-data-editor-x-spreadsheet {\n    top: 50%;\n    bottom: 0 !important;\n    width: 100% !important;\n    height: 50% !important;\n    overflow-y: scroll;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
