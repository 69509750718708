import type { DataView } from "./matrixData.types";
import { SheetData } from "x-data-spreadsheet";

export const simpleMatrixData: DataView = {
  matrix: {
    columns: {
      levels: [
        {
          sources: [
            { displayName: "ACT", roles: { Values: true }, type: { numeric: true }, queryName: "ACT", index: 0 },
            { displayName: "BUD", roles: { Values: true }, type: { numeric: true }, queryName: "BUD", index: 1 },
          ],
        },
      ],
      root: {
        children: [{ level: 0 }],
      },
    },
    rows: {
      levels: [
        {
          sources: [
            { displayName: "Category", roles: { Category: true }, type: { text: true }, queryName: "Category" },
          ],
        },
      ],
      root: {
        children: [
          { level: 0, value: "Austria", values: { 0: { value: 570000 }, 1: { value: 470000 } }, isSubtotal: false },
          { level: 0, value: "Belgium", values: { 0: { value: 56000 }, 1: { value: 46000 } }, isSubtotal: false },
          { level: 0, value: "France", values: { 0: { value: 140000 }, 1: { value: 40000 } }, isSubtotal: false },
          { level: 0, value: "Germany", values: { 0: { value: 345000 }, 1: { value: 245000 } }, isSubtotal: false },
          { level: 0, value: "Poland", values: { 0: { value: 78000 }, 1: { value: 68000 } }, isSubtotal: false },
          { level: 0, value: "Sweden", values: { 0: { value: 77000 }, 1: { value: 67000 } }, isSubtotal: false },
        ],
      },
    },
    valueSources: [
      { displayName: "ACT", roles: { Values: true }, type: { numeric: true }, queryName: "ACT", index: 0 },
      { displayName: "BUD", roles: { Values: true }, type: { numeric: true }, queryName: "BUD", index: 1 },
    ],
  },
  rowFields: [],
  columnFields: [],
  valueFields: ["ACT", "BUD"],
  rowGrandTotal: false,
  columnGrandTotal: false,
  rowNames: [],
  columnNames: [],
  valueNames: ["ACT", "BUD"],
  metadata: {
    columns: [
      { displayName: "Category", roles: { Category: true }, type: { text: true }, queryName: "Category" },
      { displayName: "ACT", roles: { Values: true }, type: { numeric: true }, queryName: "ACT", index: 0 },
      { displayName: "BUD", roles: { Values: true }, type: { numeric: true }, queryName: "BUD", index: 1 },
    ],
  },
};

export const structureTableNumbersMockData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Category" }, 1: { text: "ACT" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" } } },
  },
};

export const structureTableWithChartMockData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Category" }, 1: { text: "ACT" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" } } },
  },
};

export const comparisonTableOneMockData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Category" }, 1: { text: "ACT" }, 2: { text: "BU" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "150" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "170" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "155" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "160" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "110" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" } } },
  },
};

export const comparisonTableTwoMockData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Category" }, 1: { text: "ACT" }, 2: { text: "PY" }, 3: { text: "BU" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "155" }, 3: { text: "150" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "170" }, 3: { text: "160" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" }, 3: { text: "160" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "150" }, 3: { text: "170" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "145" }, 3: { text: "155" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "130" }, 3: { text: "160" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "115" }, 3: { text: "110" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" }, 3: { text: "105" } } },
  },
};

export const comparisonTableThreeMockData: SheetData = {
  rows: {
    0: {
      cells: { 0: { text: "Category" }, 1: { text: "ACT" }, 2: { text: "PY" }, 3: { text: "BU" }, 4: { text: "FC" } },
    },
    1: {
      cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "155" }, 3: { text: "150" }, 4: { text: "160" } },
    },
    2: {
      cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "170" }, 3: { text: "160" }, 4: { text: "145" } },
    },
    3: {
      cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" }, 3: { text: "160" }, 4: { text: "145" } },
    },
    4: {
      cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "150" }, 3: { text: "170" }, 4: { text: "160" } },
    },
    5: {
      cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "145" }, 3: { text: "155" }, 4: { text: "145" } },
    },
    6: {
      cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "130" }, 3: { text: "160" }, 4: { text: "130" } },
    },
    7: {
      cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "115" }, 3: { text: "110" }, 4: { text: "130" } },
    },
    8: {
      cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" }, 3: { text: "105" }, 4: { text: "120" } },
    },
  },
};

export const calculationTableMockData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Category" }, 1: { text: "AC" }, 2: { text: "PL" } } },
    1: { cells: { 0: { text: "Net Sales" }, 1: { text: "5400" }, 2: { text: "4900" } } },
    2: { cells: { 0: { text: "Cost of goods sold" }, 1: { text: "3500" }, 2: { text: "3100" } } },
    3: { cells: { 0: { text: "Gross profit" }, 1: { text: "1900" }, 2: { text: "1800" } } },
    4: { cells: { 0: { text: "Other operating income" }, 1: { text: "1636" }, 2: { text: "1758" } } },
    5: { cells: { 0: { text: "SG&A" }, 1: { text: "1887" }, 2: { text: "1402" } } },
    6: { cells: { 0: { text: "Operating profit" }, 1: { text: "1649" }, 2: { text: "2156" } } },
    7: { cells: { 0: { text: "Taxes" }, 1: { text: "1348" }, 2: { text: "1200" } } },
    8: { cells: { 0: { text: "Net profit" }, 1: { text: "301" }, 2: { text: "956" } } },
  },
};

export const emptyTableData: DataView = {
  matrix: {
    columns: {
      levels: [{ sources: [{ displayName: "" }] }],
      root: {},
    },
    rows: {
      levels: [{ sources: [{ displayName: "" }] }],
      root: {},
    },
    valueSources: [{ displayName: "" }],
  },
  rowFields: [],
  columnFields: [],
  valueFields: [],
  rowGrandTotal: false,
  columnGrandTotal: false,
  metadata: { columns: [] },
  rowNames: [],
  columnNames: [],
  valueNames: [],
};
