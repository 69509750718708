import Spreadsheet, { SheetData } from "x-data-spreadsheet";
import {
  DataView as TableDataView,
  getOfficeStorageTableData,
  setOfficeStorageMatrixData,
  setOfficeStorageTableData,
  setOfficeStorageXSpreadsheetData,
  validateTableData,
  validateTableDataForSmallMultiples,
} from "@zebrabi/table-data";
import { DataView as MatrixDataView } from "@zebrabi/matrix-data";
import {
  getInitialXSpreadSheetData,
  VisualType,
  parseChartData,
  parseTablesData,
  MIN_COLUMN_COUNT_FOR_SMALL_MULTIPLES, chartsSupportSmallMultiples,
} from "../helpers/xSpreadsheetHelper";
import { X_SPREADSHEET_ROOT_ID } from "../constants";
import "./index.css";
import { setXSpreadSheetData } from "../store/xSpreadsheetStore";
import { getOfficeSettings } from "@zebrabi/office-settings";

let xSpreadsheetRoot = null;
let spreadsheet = null;

function addXSpreadsheetTargetElement(target: HTMLElement) {
  xSpreadsheetRoot = document.createElement("div");
  xSpreadsheetRoot.setAttribute("id", X_SPREADSHEET_ROOT_ID);
  target.append(xSpreadsheetRoot);
}

function reAttachXSpreadsheetToTargetElement(target: HTMLElement) {
  target.append(xSpreadsheetRoot);
}

const options = {
  showToolbar: false,
  showBottomBar: false,
  view: {
    height: () => 2000,
    width: () => 1500,
  },
  row: {
    len: 100,
    height: 20,
  },
  col: {
    len: 26,
    width: 80,
    indexWidth: 60,
    minWidth: 60,
  },
  style: {
    bgcolor: "#ffffff",
    align: "left" as const,
    valign: "middle" as const,
    textwrap: false,
    strike: false,
    underline: false,
    color: "#0a0a0a" as const,
    font: {
      name: "Helvetica" as "Helvetica",
      size: 8,
      bold: false,
      italic: false as false,
    },
  },
};

function loadXSpreadsheet({ visualType }: { visualType: VisualType }) {
  function update(data) {
    let dataView: TableDataView | MatrixDataView | null = null;
    if (visualType === VisualType.CHARTS) {
      dataView = parseChartData(data);
    }
    if (visualType === VisualType.TABLES) {
      dataView = parseTablesData(data);
    }

    if (!dataView) {
      throw new Error("Error parsing spreadsheet data");
    }

    if (visualType === VisualType.CHARTS) {
      setOfficeStorageTableData(dataView as TableDataView);
    }

    if (visualType === VisualType.TABLES) {
      setOfficeStorageMatrixData(dataView as MatrixDataView);
      setOfficeStorageXSpreadsheetData(data);
    }

    setXSpreadSheetData(dataView);
  }

  spreadsheet = new Spreadsheet(xSpreadsheetRoot, options)
    .loadData(getInitialXSpreadSheetData({ visualType }))
    .change((sheetData) => {
      update(sheetData);
    });

  document.addEventListener("FORMULA_UPDATE", (data) => {
    update(data.detail);
  });
}

export function updateSpreadsheet(sheetData: SheetData) {
  if (!spreadsheet) {
    console.log("Update spreadsheet failed", sheetData);
  }
  else {
    spreadsheet.loadData(sheetData);
  }
}

export function mountXSpreadsheet({ visualType }: { visualType: VisualType }, target: HTMLElement) {
  if (xSpreadsheetRoot) return reAttachXSpreadsheetToTargetElement(target);
  addXSpreadsheetTargetElement(target);
  loadXSpreadsheet({ visualType });
  if (visualType === VisualType.CHARTS) {
    //TODO to commit: this was a quick fix for when the number of values is lower than 5. For more details see https://zebrabi.atlassian.net/browse/ZVE-929
    const selectedChart: string = getOfficeSettings("ChartChooserStore")?.selectedChart.trim();
    const isSmallMultipleOrStacked = chartsSupportSmallMultiples.includes(selectedChart);
    const tableData = getOfficeStorageTableData();
    if (tableData?.values?.length >= MIN_COLUMN_COUNT_FOR_SMALL_MULTIPLES || isSmallMultipleOrStacked) {
      setXSpreadSheetData(validateTableDataForSmallMultiples(tableData));
    } else {
      setXSpreadSheetData(validateTableData(tableData));
    }
  }
}
