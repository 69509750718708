import { DataView } from "@zebrabi/matrix-data";
import type { SheetData } from "x-data-spreadsheet";
import { setOfficeStorageXSpreadsheetData } from "@zebrabi/table-data";
import { looksLikeCommentHeader } from "@zebrabi/data-helpers/fieldAssignment";
import { getTablesMetadata } from "./tablesMetadata";
import { parseRowHierarchyDataView } from "./hierarchyParsing";

// PPT
export function tryParseHierarchicalDataView(data: SheetData, categoryColumns: number): DataView {
    let matrixDataView: DataView = null;
    try {
        matrixDataView = initDataView(data, categoryColumns);

        let rowLabelsValues: string[][] = getCategorylabels(data, categoryColumns);
        let columnLabelsValues = <any[]>matrixDataView.valueFields;
        let dataValues: unknown[][] = getDataValues(data, categoryColumns);

        const isPivotTable = false;
        const hasComments = columnLabelsValues.some(c => looksLikeCommentHeader(c));

        getTablesMetadata(matrixDataView, isPivotTable, hasComments);

        //console.log(rowLabelsValues, columnLabelsValues, dataValues)
        parseRowHierarchyDataView(matrixDataView, rowLabelsValues, columnLabelsValues, dataValues, null);
        //TOcheck use: dv = dh.parseRangeDataView(dv, null, null, null, null, rowLabelsValues, columnLabelsValues, dataValues, null, null);
        // save source data to office storage
        setOfficeStorageXSpreadsheetData(data);

    } catch (e) {
        console.log("tryParseHierarchicalDataView:", e);
        matrixDataView = null;
    }

    return matrixDataView;
}

function initDataView(sheetData: SheetData, categoryColumns: number): DataView {
    let categoryFields = [];
    const groupFields = [];
    let measureFields = [];
    const rowGrandTotal = false;
    const columnGrandTotal = false;

    const categoryColumnsHeaders = Object.values(sheetData.rows[0]?.cells || []) // 
        .filter((_cell, index) => index < categoryColumns)
        .filter((cell) => !!cell?.text)
        .map((cell) => cell?.text);

    const measureColumnsHeaders = Object.values(sheetData.rows[0]?.cells || []) // First row without the first cell
        .filter((_cell, index) => index > categoryColumns - 1)
        .filter((cell) => !!cell?.text)
        .map((cell) => cell?.text);

    categoryFields = categoryColumnsHeaders;
    measureFields = measureColumnsHeaders;

    const dataView: DataView = {
        metadata: { columns: [] },
        matrix: null,
        rowFields: categoryFields,
        columnFields: groupFields,
        valueFields: measureFields,
        rowGrandTotal: rowGrandTotal,
        columnGrandTotal: columnGrandTotal,
        rowNames: categoryFields,
        columnNames: groupFields,
        valueNames: measureFields
    };
    return dataView;
}

function getCategorylabels(data: SheetData, categoryColumns: number): string[][] {
    const categoryLabels: string[][] = [];

    const sourceDataRows = Object.keys(data.rows)
        .filter((key, i) => i > 0)  // skip first row
        .map((key) => {
            return data.rows[+key];
        });

    let currentHierarchy: string[] = [];

    sourceDataRows.forEach((row) => {
        let rowLabels: string[] = [];
        for (let i = 0; i < categoryColumns; i++) {
            const cell = row.cells[i];

            if (currentHierarchy?.[i] === cell.text) {
                rowLabels.push("");
            }
            else {
                rowLabels.push(cell.text);

                if (currentHierarchy?.[i] !== undefined) {
                    currentHierarchy[i] = cell.text;
                }
                else {
                    currentHierarchy.push(cell.text);
                }
            }
        }

        categoryLabels.push(rowLabels);
    });
    return categoryLabels;
}

function getDataValues(data: SheetData, categoryColumns: number): unknown[][] {
    const measureValues: unknown[][] = [];

    const sourceDataRows = Object.keys(data.rows)
        .filter((key, i) => i > 0)  // skip first row
        .map((key) => {
            return data.rows[+key];
        });

    sourceDataRows.forEach((row) => {
        let rowValues: unknown[] = [];
        Object.keys(row.cells).forEach((key) => {
            const index = Number(key);
            if (index >= categoryColumns) {
                const cell = row?.cells[index];
                rowValues.push(typeof cell?.text === "number" ? +cell?.text : cell.text ?? ""); //check?: handle numbers and/or string for comments
            }
        });
        measureValues.push(rowValues);
    });
    return measureValues;
}
