import { DataView, DataViewMatrixNode, DataViewMetadataColumn } from "@zebrabi/matrix-data";
import { MeasureRoles, tryGetMeasureRoleFromName } from "./fieldAssignment";

export function getTablesMetadata(dv: DataView, isPivotTable: boolean, hasCommentCol: boolean): void {
    dv.rowFields.forEach((rf, ix) => {
        dv.metadata.columns.push({
            displayName: dv.rowNames[ix] || "Category",
            roles: { "Category": true },
            type: { "text": true },
            queryName: dv.rowNames[ix] || "Category",
        });
    });
    dv.columnFields.forEach((cf, ix) => {
        dv.metadata.columns.push({
            displayName: dv.columnNames[ix],
            roles: { "Group": true },
            type: { "text": true },
            queryName: dv.columnNames[ix],
        });
    });

    let usedRoles = new Map<number, MeasureRoles>();
    let usedIndexes: number[] = [];
    let metaValueColumns: DataViewMetadataColumn[] = [];

    // auto assign measure roles
    dv.valueFields.forEach((field: any, index: number) => {
        const measureName = dv.valueNames[index];
        const measureRole = tryGetMeasureRoleFromName(measureName);
        if (measureRole && isValidNewRole(measureRole, metaValueColumns)) {
            metaValueColumns.push(getMetaDataColumn(measureName, measureRole, index));
            usedRoles.set(index, measureRole);
            usedIndexes.push(index);
        }
    });

    // assign remaining measures
    dv.valueFields.forEach((field: any, index: number) => {
        if (!usedIndexes.includes(index)) {
            const measureName = dv.valueNames[index];
            const role = getNextRole(metaValueColumns, isPivotTable, hasCommentCol);
            metaValueColumns.push(getMetaDataColumn(measureName, role, index));
            usedRoles.set(index, role);
            usedIndexes.push(index);
        }
    });

    // sort cols by index
    metaValueColumns.sort((c1, c2) => c1.index - c2.index);
    //usedRoles.((r1, r2) => metaValueColumns.findIndex(c => c.roles[r1]) - metaValueColumns.findIndex(c => c.roles[r2]));

    dv.metadata.columns = dv.metadata.columns.concat(metaValueColumns);
    dv.metadata.measureRoles = metaValueColumns.map(c => <MeasureRoles>Object.keys(c.roles)[0]);
}

function getMetaDataColumn(measureName: string, measureRole: MeasureRoles, index: number): DataViewMetadataColumn {
    return {
        displayName: measureName,
        roles: {
            [measureRole]: true
        },
        type: measureRole === MeasureRoles.Comments ? { "text": true } : { "numeric": true },
        queryName: measureName,
        index: index
    };
}

function getNextRole(metaValueColumns: DataViewMetadataColumn[], isPivotTable: boolean, hasCommentCol: boolean): MeasureRoles {
    if (!metaValueColumns.some(c => c.roles[MeasureRoles.Values])) {
        return MeasureRoles.Values;
    }
    else if (!metaValueColumns.some(c => c.roles[MeasureRoles.PreviousYear])) {
        return MeasureRoles.PreviousYear;
    }
    else if (!metaValueColumns.some(c => c.roles[MeasureRoles.Plan])) {
        return MeasureRoles.Plan;
    }
    else if (!metaValueColumns.some(c => c.roles[MeasureRoles.Forecast])) {
        return MeasureRoles.Forecast;
    }
    else if (hasCommentCol && !isPivotTable && !metaValueColumns.some(c => c.roles[MeasureRoles.Comments])) {
        return MeasureRoles.Comments;
    }
    else {
        return MeasureRoles.Values;
    }
}

function isValidNewRole(measureRole: MeasureRoles, metaValueColumns: DataViewMetadataColumn[]): boolean {
    const roleCount = metaValueColumns.filter(c => c.roles[measureRole]).length;
    const maxRoleCount = getMaxRoleCount(measureRole);
    return roleCount < maxRoleCount;
}

function getMaxRoleCount(role: MeasureRoles): number {
    switch (role) {
        case MeasureRoles.Values:
            return 21;
        case MeasureRoles.PreviousYear:
            return 1;
        case MeasureRoles.Plan:
            return 3;
        case MeasureRoles.Forecast:
            return 3;
        case MeasureRoles.Comments:
            return 1;
    }
}
