import { DOMStorage } from "@zebrabi/dom-storage";

const TABLE_DATA_EDITOR_STORE_ID = "table-data-editor-store";
const TABLE_DATA_EDITOR_DATA_ATTRIBUTE = "data-zebrabi-table-data-editor-store";

export type TableDataEditorData = {
  isVisible: boolean;
};

let tableDataEditorStore: DOMStorage<TableDataEditorData>;

export function initEditorStore() {
  tableDataEditorStore = new DOMStorage<TableDataEditorData>(
    TABLE_DATA_EDITOR_STORE_ID,
    TABLE_DATA_EDITOR_DATA_ATTRIBUTE
  );
}

export function setTableDataEditorData(data: TableDataEditorData) {
  return tableDataEditorStore.set(data);
}

export function subscribeToTableDataEditorData(handler: (data: TableDataEditorData) => void) {
  return tableDataEditorStore.subscribe(handler);
}
