export enum MeasureRoles {
    Values = "Values",
    PreviousYear = "PreviousYear",
    Plan = "Plan",
    Forecast = "Forecast",
    Comments = "Comments"
}

export enum CategoricalRoles {
    Category = "Category",
    Group = "Group"
}

export function tryGetMeasureRoleFromName(measureName: string): MeasureRoles {
    if (!measureName || !measureName.toLowerCase || measureName.length === 0) {
        return null;
    }

    if (measureName.startsWith("Sum of ")) {
        measureName = measureName.replace("Sum of ", "");
    }
    measureName = measureName.trim();

    if (looksLikeActual(measureName)) {
        return MeasureRoles.Values;
    }
    else if (looksLikePreviousYear(measureName)) {
        return MeasureRoles.PreviousYear
    }
    else if (looksLikePlan(measureName)) {
        return MeasureRoles.Plan;
    }
    else if (looksLikeForecast(measureName)) {
        return MeasureRoles.Forecast;
    }
    else if (looksLikeCommentHeader(measureName)) {
        return MeasureRoles.Comments;
    }
    else {
        return null;
    }
}

export function looksLikeActual(header: string): boolean {
    if (!header) {
        return false;
    }
    const headerLC = header.toLowerCase();
    return headerLC === "actual" ||
        headerLC === "act" ||
        headerLC === "ac" ||
        headerLC === "ist";
}

export function looksLikePreviousYear(columnHeader: string): boolean {
    if (!columnHeader) {
        return false;
    }
    const headerLC = columnHeader.toLowerCase();
    return headerLC === "previous year" ||
        headerLC === "py" ||
        headerLC === "last year" ||
        headerLC === "ly";
}

export function looksLikePlan(columnHeader: string): boolean {
    if (!columnHeader) {
        return false;
    }
    const headerLC = columnHeader.toLowerCase();
    return headerLC.startsWith("pl") ||
        headerLC === "budget" ||
        headerLC === "bu" ||
        headerLC === "bud" ||
        headerLC === "target" ||
        headerLC === "tgt";
}

export function looksLikeForecast(columnHeader: string): boolean {
    if (!columnHeader) {
        return false;
    }
    const headerLC = columnHeader.toLowerCase();
    return headerLC.startsWith("forecast") ||
        headerLC.startsWith("fc");
}

export function looksLikeCommentHeader(header: string): boolean {
    if (!header || !header.toLowerCase) {
        return false;
    }
    const headerLC = header.toLowerCase();
    return headerLC.startsWith("comm") || headerLC.startsWith("kom");
    //return headerLC.includes("comm") || headerLC.includes("kom");
}

export function getMeasureRole(roles: { [name: string]: boolean; }): MeasureRoles {
    if (roles[MeasureRoles.Values]) {
        return MeasureRoles.Values;
    }
    else if (roles[MeasureRoles.PreviousYear]) {
        return MeasureRoles.PreviousYear;
    }
    else if (roles[MeasureRoles.Plan]) {
        return MeasureRoles.Plan;
    }
    else if (roles[MeasureRoles.Forecast]) {
        return MeasureRoles.Forecast;
    }
    else if (roles[MeasureRoles.Comments]) {
        return MeasureRoles.Comments;
    }
    return null;
}
