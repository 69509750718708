import { mountXSpreadsheet } from "./x-spreadsheet";
import { initXSpreadSheetStoreWithTableData, initXSpreadSheetStoreWithMatrixData } from "./store/xSpreadsheetStore";
import { initEditorStore } from "./store/editorStore";
import "./index.css";
import { VisualType } from "./helpers/xSpreadsheetHelper";

const ROOT_ELEMENT_PARENT_CLASS = "table-data-editor-parent";

function addContainerClassToRootDOMElement(target: HTMLElement) {
  target.classList.add(ROOT_ELEMENT_PARENT_CLASS);
}

export function mountTableDataEditor({ visualType }: { visualType: VisualType }, target: HTMLElement) {
  addContainerClassToRootDOMElement(target);
  // TableDataEditorToggle has been moved to GlobalToolbar
  mountXSpreadsheet({ visualType }, target);
}

export function initTableDataEditorStore({ visualType }: { visualType: VisualType }) {
  if (visualType === VisualType.CHARTS) initXSpreadSheetStoreWithTableData();
  if (visualType === VisualType.TABLES) initXSpreadSheetStoreWithMatrixData();
  initEditorStore();
}
