import { DataView as TableDataView } from "@zebrabi/table-data";
import { DataView as MatrixDataView } from "@zebrabi/matrix-data";

import { LOCAL_STORAGE_TABLE_DATA, LOCAL_STORAGE_MATRIX_DATA, LOCAL_STORAGE_X_SPREADSHEET_DATA } from "../../constants";
import { parseData } from "../tableDataHelper";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { getOfficeSettings } from "@zebrabi/office-settings";
import { SheetData } from "x-data-spreadsheet";

/* global Office */

// TableData

export function setOfficeStorageTableData(tableData: TableDataView): void {
  persistManagerInstance.update({
    objectName: LOCAL_STORAGE_TABLE_DATA,
    properties: JSON.stringify(tableData),
  });
}

export function getOfficeStorageTableData(): TableDataView {
  const tableData = getOfficeSettings(LOCAL_STORAGE_TABLE_DATA);
  return parseData(tableData);
}

// MatrixData

export function setOfficeStorageMatrixData(matrixData: MatrixDataView): void {
  persistManagerInstance.update({
    objectName: LOCAL_STORAGE_MATRIX_DATA,
    properties: JSON.stringify(matrixData),
  })
}

export function getOfficeStorageMatrixData(): MatrixDataView {
  const tableData = getOfficeSettings(LOCAL_STORAGE_MATRIX_DATA);
  return parseData(tableData);
}

export function setOfficeStorageXSpreadsheetData(spreadsheetData: SheetData): void {
  persistManagerInstance.update({
    objectName: LOCAL_STORAGE_X_SPREADSHEET_DATA,
    properties: JSON.stringify(spreadsheetData),
  })
}

export function getOfficeStorageXSpreadsheetData(): SheetData {
  const spreadsheetData = getOfficeSettings(LOCAL_STORAGE_X_SPREADSHEET_DATA);
  return parseData(spreadsheetData);
}
