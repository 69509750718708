import "core-js/actual/structured-clone";
import { simpleTableData, emptyTableData } from "../../tableData.mock";
import type { DataView } from "../../tableData.types";

export function validateTableData(tableData: DataView): DataView {
  let validatedTableData = tableData;
  // Add grouped method to values
  if (!tableData?.values?.grouped) {
    validatedTableData = structuredClone(tableData);
    if (validatedTableData) {
      validatedTableData.values.grouped = () => [{ values: validatedTableData.values }];
    }
  }
  return validatedTableData;
}

export function validateTableDataForSmallMultiples(tableData: DataView): DataView {
  let validatedTableData = tableData;
  // Add grouped method to values
  if (!tableData?.values?.grouped) {
    validatedTableData = structuredClone(tableData);
    if (validatedTableData) {
      validatedTableData.values.grouped = () =>
        validatedTableData.values.map((vals) => ({
          name: vals.source.groupName,
          values: [vals],
        }));
    }
  }
  return validatedTableData;
}

export function isValidTableData(maybeTableData: any): boolean {
  const { values, categories } = maybeTableData || {};
  return (
    (values || []).reduce((isValid, { source: { displayName }, values = [] }) => {
      return isValid || (displayName && values[0] && values[1]);
    }, false) &&
    categories[0]?.values[0] &&
    categories[0]?.values[1]
  );
}

export function prepareTableData(tableData: DataView): DataView {
  try {
    if (!tableData) {
      throw new Error("Missing table data");
    }
    return validateTableData(tableData);
  } catch (error) {
    console.log("Could not read table data", error);
    return validateTableData(emptyTableData);
  }
}

export function parseTableData(tableData: string): DataView {
  try {
    return prepareTableData(JSON.parse(tableData));
  } catch (error) {
    console.log("Could not read table data", error);
    return validateTableData(simpleTableData);
  }
}

export function parseData<T>(data: string): T {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.log("Could not read table data", error);
    return null;
  }
}

export function stringWithCommaToFloat(string: string = ""): number | string {
  const maybeNumber: number | typeof NaN = parseFloat(String(string).replace(/,/g, ""));
  return isNaN(maybeNumber) ? string : maybeNumber;
}
