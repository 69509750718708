import { DataView, DataViewMatrixNode, DataViewMetadataColumn } from "@zebrabi/matrix-data";
import { MeasureRoles } from "./fieldAssignment";
import { flagHandler } from '@zebrabi/zebrabi-core';

export function parseRowHierarchyDataView(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][], layout: Excel.PivotLayout) {
    dataView.matrix = { rows: null, columns: null, valueSources: [] };
    //rows
    let categoryColumns = dataView.metadata.columns.filter(f => f.roles["Category"]);
    let commentColumns = dataView.metadata.columns.filter(f => f.roles[MeasureRoles.Comments]);
    const commentsIndex = commentColumns.length > 0 ? commentColumns[0].index : null;
    const pivotTableHasSubTotals = layout && layout.subtotalLocation !== Excel.SubtotalLocationType.off;

    dataView.matrix.rows = {
        levels: categoryColumns.map(cc => {
            return { sources: [cc] }
        }),
        root: {
            children: flagHandler.has("office-tables-hierarchy-parsing-refactor") ? (layout ?
                getMatrixRowsRecursiveChildrenPivotTable(0, rowLabelsValues.length - 1, 0, rowLabelsValues, dataValues, layout, pivotTableHasSubTotals)
                : getMatrixRowsRecursiveChildrenForRange(0, rowLabelsValues.length - 1, 0, rowLabelsValues, dataValues, null)) :
                getMatrixRowsRecursiveChildren(0, rowLabelsValues.length - 1, 0, rowLabelsValues, dataValues, layout, false, commentColumns?.length > 0, null, commentsIndex)
        }
    };

    //columns
    let valueColumns = dataView.metadata.columns.filter(f => f.roles[MeasureRoles.Values] || f.roles[MeasureRoles.PreviousYear] ||
        f.roles[MeasureRoles.Plan] || f.roles[MeasureRoles.Forecast] || f.roles[MeasureRoles.Comments]);
    let groupColumns = dataView.metadata.columns.filter(f => f.roles["Group"]);
    let levels = [];
    if (groupColumns.length) {
        groupColumns.forEach(gc => {
            levels.push({ sources: [gc] })
        });
    }
    if (!levels.length || valueColumns.length > 1) {
        levels.push({ sources: valueColumns })
    }
    dataView.matrix.columns = {
        levels: levels,
        root: {
            children: getMatrixColumnsChildren(valueColumns, groupColumns, columnLabelsValues, dataView.rowGrandTotal)
        }
    }

    //valueSources
    dataView.matrix.valueSources = valueColumns;
}

export function getMatrixColumnsChildren(valueColumns: DataViewMetadataColumn[], groupColumns: DataViewMetadataColumn[], columnLabelsValues: any[][], hasGrandTotal: boolean): DataViewMatrixNode[] {
    if (groupColumns.length && columnLabelsValues[1]) {
        let groupNames = columnLabelsValues[1].filter ? //pivot table : range
            columnLabelsValues[1].filter(val => val !== "") : columnLabelsValues;

        if (hasGrandTotal && valueColumns.length > 1) {  // if grand total On, remove total column labels for other measures except AC
            groupNames = groupNames.slice(0, 1 - valueColumns.length);
        }

        let res: any[] = groupNames.map((gn, ix) => {
            return {
                level: 0,
                value: gn,
                children: undefined,
                // isSubtotal: columnGrandTotal && ix == groupNames.length - 1,
            }
        });

        if (hasGrandTotal) {
            res.push({ level: 0, children: undefined, isSubtotal: true })
        }
        // go over all...
        if (valueColumns.length > 1) {
            res.forEach((c, ix) => {
                c.children = valueColumns.map(vc => {
                    return { level: 1 }
                })
            })
        }
        return res;
    } else {
        return valueColumns.map(vc => {
            return { level: 0 }
        });
    }
}

//TODO: refactor/rewrite or add comments to this function, explain subtotals handling logic
// start index: 0, end index: last cell index.
export function getMatrixRowsRecursiveChildren(startIndex: number, endIndex: number, level: number, rowLabelsValues: any[][], dataValues: any[][],
    layout: Excel.PivotLayout, parseRange: boolean, hasComments: boolean, range: Excel.Range, commentsColIndex: number): DataViewMatrixNode[] {
    let children = [];
    if (level < rowLabelsValues[0].length - 1) {
        // If not on last level, get children recursively...
        // split indices over non-empty values at current level, for each go into recursion...
        let currentStartIndex = startIndex;
        //while (currentStartIndex <= endIndex) {
        while (currentStartIndex <= (layout ? endIndex - 1 : endIndex)) {
            // create the child, populate with (parent) data
            let returnedEndIndex = getEndIndex(currentStartIndex, level, rowLabelsValues, endIndex);
            let currentEndIndex = Math.min(endIndex, returnedEndIndex);
            if ((layout && !layout.showColumnGrandTotals || layout === null)
                && level < rowLabelsValues[0].length - 1 //level == 0 
                && returnedEndIndex == endIndex + 1) {
                // currentEndIndex++;
                currentEndIndex = returnedEndIndex;
            }
            let currentNode: DataViewMatrixNode = {
                level: level,
                value: rowLabelsValues[currentStartIndex][level],
                children: getMatrixRowsRecursiveChildren(currentStartIndex, currentEndIndex - 1, level + 1, rowLabelsValues, dataValues, layout, parseRange, hasComments, range, commentsColIndex)
            };

            // push subtotal...
            if (isSubtotal(currentStartIndex, currentEndIndex - 1, level, rowLabelsValues)) {
                let i = currentEndIndex - 1;
                let vals = {}
                for (let j = 0; j < dataValues[i].length; j++) {
                    //const isCommentCol = hasComments && j === commentsColIndex;

                    const val = dataValues[i][j];
                    vals[j] = {
                        "value": val === "" ? null : val //isCommentCol ? val || null : this.isValidNonNullNumber(val) ? <number>val : null
                    };
                }
                currentNode.children.push({
                    level: level + 1,
                    isSubtotal: true,
                    values: vals,
                })
            }

            children.push(currentNode);
            currentStartIndex = currentEndIndex;

            // handle pivot table grand total?
            if (currentStartIndex === endIndex && level === 0 && layout) {  // non-null layout indicates pivot table
                let i = currentEndIndex;
                let vals = {}
                for (let j = 0; j < dataValues[i].length; j++) {
                    //const isCommentCol = hasComments && j === commentsColIndex;

                    const val = dataValues[i][j];
                    const parsedVal = val === "" ? null : val;

                    vals[j] = {
                        "value": parsedVal // isCommentCol ? val || null : this.isValidNonNullNumber(val) ? <number>val : null
                    };
                }
                children.push({
                    level: 0,
                    isSubtotal: true,
                    values: vals,
                })
            }
        }
    } else {
        // else parse values (last level categories), (raise start indices)...

        for (let i = startIndex; i <= endIndex; i++) {
            let vals = {};
            for (let j = 0; j < dataValues[i].length; j++) {
                const val = dataValues[i][j];
                let parsedVal = val === "" || parseRange && range?.valueTypes[i][j] === Excel.RangeValueType.error ? null : val;
                vals[j] = {
                    "value": parsedVal
                };
            }
            let node: DataViewMatrixNode = {
                level: level,
                value: rowLabelsValues[i][level],
                values: vals,
                isSubtotal: layout ? isSubtotal(startIndex, i, level - 1, rowLabelsValues, layout.showColumnGrandTotals) : false,
            }
            if (!node.isSubtotal && node.value !== "" || level === 0) {
                children.push(node);
            }
        }
    }
    return children;
}

function isSubtotal(startIndex: number, subtotalIndex: number, level: number, rowLabelValues: any[][], hasGrandTotal?: boolean): boolean {
    // if no hierarchy - special case.
    if (level === -1) {
        return hasGrandTotal && subtotalIndex === rowLabelValues.length - 1;
    }

    if (subtotalIndex > rowLabelValues.length - 1 || startIndex > rowLabelValues.length - 1) {
        return false;
    }
    if ((<string>rowLabelValues[subtotalIndex][level])?.includes(rowLabelValues[startIndex][level]) && (<string>rowLabelValues[subtotalIndex][level + 1]) === "") {
        return true;
    }
    return false;
}

function isSubtotalv2(startIndex: number, subtotalIndex: number, level: number, rowLabelValues: any[][], hasGrandTotal?: boolean): boolean {
    // if no hierarchy - special case.
    if (level === -1) {
        return hasGrandTotal && subtotalIndex === rowLabelValues.length - 1;
    }

    if (subtotalIndex > rowLabelValues.length - 1 || startIndex > rowLabelValues.length - 1) {
        return false;
    }

    const subTotalLabel = String(rowLabelValues[subtotalIndex][level]);
    const startLabel = String(rowLabelValues[startIndex][level]);
    const subTotalNextLevelLabel = String(rowLabelValues[subtotalIndex][level + 1]);

    if (subTotalLabel === "" || subTotalNextLevelLabel !== "") {
        return false;
    }

    return subTotalLabel.includes(startLabel);
}

function getEndIndex(startIndex: number, level: number, rowLabelsValues: any[][], finalEndIndex: number): number {
    let endIx = startIndex + 1;
    while ((endIx <= finalEndIndex) && (rowLabelsValues[endIx][level] === "" || isSubtotal(startIndex, endIx, level, rowLabelsValues))) {
        endIx++;
    }
    return endIx;
}

//todo add a separate function without subtotals for range function
// returns the last index of a current hierarchy branch + 1
function getEndIndex2(startIndex: number, level: number, rowLabelsValues: any[][], finalEndIndex: number, handleSubtotals: boolean): number {
    let endIx = startIndex + 1;

    if (handleSubtotals && isSubtotalv2(startIndex, endIx, level, rowLabelsValues)) {
        endIx++;
    }

    while ((endIx <= finalEndIndex) && (rowLabelsValues[endIx][level] === "")) {
        endIx++;
    }

    if (handleSubtotals && isSubtotalv2(startIndex, endIx, level, rowLabelsValues)) {
        endIx++;
    }

    return endIx;
}

/**
 *  handles Excel range, Excel table and PPT hierarchy parsing 
 * @param startIndex start index of the current hierarchy branch
 * @param endIndex end index of the current hierarchy branch
 * @param level current hiearchy level (starting from 0)
 * @param rowLabelsValues category values
 * @param dataValues data values 
 * @param range Excel range (optional, used for parsing Excel data, for PPT should be null ) 
 * @returns an array of DataViewMatrixNode objects (current hierarchy chidren)
 */
export function getMatrixRowsRecursiveChildrenForRange(startIndex: number, endIndex: number, level: number, rowLabelsValues: any[][], dataValues: any[][], range: Excel.Range): DataViewMatrixNode[] {
    let children: DataViewMatrixNode[] = [];
    if (level < rowLabelsValues[0].length - 1) {
        // If not on last level, get children recursively...
        // split indices over non-empty values at current level, for each go into recursion...
        let currentStartIndex = startIndex;

        while (currentStartIndex <= endIndex) {
            // create the child, populate with (parent) data
            const currentEndIndex = getEndIndex2(currentStartIndex, level, rowLabelsValues, endIndex, false);

            const currentNode: DataViewMatrixNode = {
                level: level,
                value: rowLabelsValues[currentStartIndex][level],
                children: getMatrixRowsRecursiveChildrenForRange(currentStartIndex, currentEndIndex - 1, level + 1, rowLabelsValues, dataValues, range)
            };

            children.push(currentNode);
            currentStartIndex = currentEndIndex;
        }
    } else {
        // else parse values (last level categories)
        for (let i = startIndex; i <= endIndex; i++) {
            let vals = {};
            for (let j = 0; j < dataValues[i].length; j++) {
                const val = dataValues[i][j];
                const parsedVal = val === "" || range && range?.valueTypes?.[i][j] === Excel.RangeValueType.error ? null : val;
                vals[j] = {
                    "value": parsedVal
                };
            }

            const node: DataViewMatrixNode = {
                level: level,
                value: rowLabelsValues[i][level],
                values: vals,
                isSubtotal: false,
            }

            if (node.value !== "") { //todo: check if this is needed
                children.push(node);
            }
        }
    }
    return children;
}

/**
 *  used for Excel pivot tables hierarchy parsing, should handle different settings for subtotals, grand totals and expanded/collapsed nodes
 * @param startIndex start index of the current hierarchy branch
 * @param endIndex end index of the current hierarchy branch
 * @param level current hiearchy level (starting from 0)
 * @param rowLabelsValues category values
 * @param dataValues data values 
 * @param layout Excel pivot table layout (contains information about subtotals and grand totals)
 * @param subTotals boolean flag to indicate that pivot table displays subtotals
 * @returns an array of DataViewMatrixNode objects (current hierarchy chidren)
 */
export function getMatrixRowsRecursiveChildrenPivotTable(startIndex: number, endIndex: number, level: number, rowLabelsValues: any[][], dataValues: any[][], layout: Excel.PivotLayout, subTotals: boolean): DataViewMatrixNode[] {
    let children: DataViewMatrixNode[] = [];
    if (level < rowLabelsValues[0].length - 1) {
        // If not on last level, get children recursively...
        // split indices over non-empty values at current level, for each go into recursion...
        let currentStartIndex = startIndex;

        //console.debug(startIndex, endIndex, level)
        // idea: check/find subtotals before while loop, then add them to the children array
        //const hasSubTotal = subTotals && startIndex !== endIndex && isSubtotal(startIndex, endIndex, level, rowLabelsValues);
        //const hasTopSubTotal = isSubtotal(startIndex, startIndex, level, rowLabelsValues);
        //console.log("hasSubTotals: ", hasSubTotal, hasTopSubTotal)

        //const loopEndIndex = hasSubTotal ? endIndex - 1 : endIndex;
        let loopEndIndex = subTotals ? endIndex - 1 : endIndex;
        if (level === 0 && layout.showColumnGrandTotals) {
            loopEndIndex--;
        }
        //const loopEndIndex = endIndex;

        //while (currentStartIndex <= endIndex) {
        while (currentStartIndex <= loopEndIndex) {
            //while (currentStartIndex <= (handlePivotTableSubTotals ? endIndex : endIndex)) {
            // create the child, populate with (parent) data
            const currentEndIndex = getEndIndex2(currentStartIndex, level, rowLabelsValues, endIndex, subTotals);
            const isCurrentSubtotal = isSubtotalv2(currentStartIndex, currentEndIndex - 1, level, rowLabelsValues);   // is subtotal OR collapsed node
            //console.debug("isCurrentSubtotal: ", isCurrentSubtotal, currentStartIndex, currentEndIndex - 1)
            let currentNode: DataViewMatrixNode = {
                level: level,
                value: rowLabelsValues[currentStartIndex][level],
                children: isCurrentSubtotal && currentStartIndex === currentEndIndex - 1 ? [] :
                    getMatrixRowsRecursiveChildrenPivotTable(currentStartIndex, currentEndIndex - 1, level + 1, rowLabelsValues, dataValues, layout, subTotals)
            };

            // push subtotal...
            if (isCurrentSubtotal) {
                const i = currentEndIndex - 1;
                let vals = {};
                for (let j = 0; j < dataValues[i].length; j++) {
                    const value = dataValues[i][j];
                    vals[j] = {
                        "value": value === "" ? null : value
                    };
                }
                //console.debug("adding subtotal: ", vals[0], rowLabelsValues[currentEndIndex - 1], level + 1)
                currentNode.children.push({
                    level: level + 1,
                    isSubtotal: true,
                    values: vals,
                });
            }
            // else {
            //     console.debug("not a subtotal: ", rowLabelsValues[currentEndIndex - 1])
            // }

            children.push(currentNode);
            currentStartIndex = currentEndIndex;

            // handle pivot table grand total?
            if (layout.showColumnGrandTotals && level === 0 && currentStartIndex === endIndex) {
                let i = currentEndIndex;
                let vals = {}
                for (let j = 0; j < dataValues[i].length; j++) {
                    const val = dataValues[i][j];
                    const parsedVal = val === "" ? null : val;

                    vals[j] = {
                        "value": parsedVal
                    };
                }
                console.debug("adding grand total?", vals)

                children.push({
                    level: 0,
                    isSubtotal: true,
                    values: vals,
                });
            }
        }
    } else {
        // else parse values (last level categories)
        for (let i = startIndex; i <= endIndex; i++) {
            let vals = {};
            for (let j = 0; j < dataValues[i].length; j++) {
                const val = dataValues[i][j];
                const parsedVal = val === "" ? null : val;
                vals[j] = {
                    "value": parsedVal
                };
            }

            const node: DataViewMatrixNode = {
                level: level,
                value: rowLabelsValues[i][level],
                values: vals,
                isSubtotal: isSubtotalv2(startIndex, i, level - 1, rowLabelsValues, layout.showColumnGrandTotals),
            }

            // if (node.isSubtotal) {
            //     console.log("last level subtotal: ", rowLabelsValues[i][level], vals[0], node)
            // }

            if (!node.isSubtotal && node.value !== "" || level === 0) { //???
                children.push(node);
            }
            else if (children.length === 0) {
                children.push(node);
            }
            // else {
            //     console.debug("skipping last level subtotal: ", rowLabelsValues[i][level], vals[0], node, children)
            // }
        }
    }
    return children;
}
