import { Observable } from "@zebrabi/observable";
import { getOfficeSettings, setOfficeSettings } from "@zebrabi/office-settings";

export const TABLE_CHOOSER_STORE = "TableChooserStore";

type StoreData = {
  isVisible: boolean;
  selectedDimension: string;
  selectedTable: string;
  areComments: boolean;
};

class Store {
  static data;
  static initialData = {
    isVisible: true,
    selectedDimension: "structure",
    selectedTable: "",
    areComments: true,
  };

  constructor() {
    const officeData = getOfficeSettings(TABLE_CHOOSER_STORE);
    if (Store.data && !officeData) return;
    Store.data = new Observable<StoreData>(officeData || Store.initialData);
  }

  static get() {
    return Store.data.get();
  }

  static set(value: Partial<StoreData>) {
    const nextValue = {
      ...Store.data.get(),
      ...value,
    };
    Store.data.set(nextValue);
    setOfficeSettings(TABLE_CHOOSER_STORE, nextValue);
  }

  static subscribe(handler: (value: StoreData) => void) {
    Store.data.subscribe(handler);
  }

  static unsubscribe() {
    Store.data.unsubscribe();
  }
}

export type { StoreData as TablesChooserStoreData };
export { Store as TablesChooserStore };
