import PersistObserver from "./observers/PersistObserver";

export default class PersistManager extends PersistObserver {
    public static CLASS_NAME = "PersistManager";
    public static _instance: PersistManager = new PersistManager();

    private persistQueue: Map<string, any> = new Map();
    private readonly saveDelay: number = 500;
    private timer: ReturnType<typeof setTimeout>;
    private promises: Promise<any>[] = [];

    constructor() {
        super();
    }

    update(data: PersistObject): void {
        this.persistQueue.set(data.objectName, data.properties);
        this.initPersist();
    }

    public flushQueue(){
        return new Promise((resolve, reject) => {
            for (const [objectName, properties] of this.persistQueue.entries()){
                Office.context.document.settings.set(objectName, properties);
            }

            try {
                Office.context.document.settings.saveAsync((result) => {
                    resolve(result);
                });

                this.persistQueue.clear();
            } catch (e) {
                console.error("Issue saving properties", e);
                reject(e);
            }
        });
    }

    private initPersist() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            Promise.all(this.promises).then(() => {
                const promise = this.flushQueue().then((result) => {
                    if (this.persistQueue.size > 0) {
                        this.initPersist();
                    }
                    this.promises.splice(this.promises.indexOf(promise),1);
                }).catch((e) => {
                    if (process.env.DEBUG_LOG == "true") {
                        console.debug(e);
                    }
                });

                this.promises.push(promise);
            })
        }, this.saveDelay);
    }
}

export const persistManagerInstance = PersistManager._instance;

export interface PersistObject {
    objectName: string, // IMPORTANT: make sure it's not named the same way the proxy is!!
    properties: any,
}
